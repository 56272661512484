<template>
  <div>
    <div>
      <action-header
          :is-loading="!order"
          :actions-model="headerModel"
          :page="page"
      />

      <div
          v-if="hasDeliveryServiceStatus && order.state === 'error'"
          class="q-pa-md text-center bg-red-5 rounded"
      >
        <strong>{{ deliveryRequest.deliveryServiceStatus }}</strong>
      </div>

      <div
          v-if="hasDeliveryServiceStatus && order.state !== 'error'"
          class="q-pa-md text-center bg-amber-6 rounded"
      >
        <strong>{{ deliveryRequest.deliveryServiceStatus }}</strong>
      </div>

      <div
          v-if="order && order.stateDescription && order.state === 'error'"
          class="q-pa-md text-center bg-red-5 rounded"
      >
        <strong>{{ order.stateDescription }}</strong>
      </div>

      <div
          v-if="order && order.stateDescription && order.state !== 'error'"
          class="q-pa-md text-center bg-amber-6 rounded"
      >
        <strong>{{ order.stateDescription }}</strong>
      </div>


      <div
          v-if="hasOrderErrors"
          class="q-pa-md text-center bg-red-5 rounded"
      >
        <strong>{{ order.orderErrors }}</strong>
      </div>

      <div class="q-pa-md">
        <q-legend
            v-if="hasBaseAccess && hasProfile"
            class="q-mb-sm"
            :label="$t('Customer data')"
        />

        <c-profile
            v-if="hasBaseAccess && hasProfile"
            :is-loading="!order"
            :disabled="isDisabledFields"
            :model="(order && order._embedded.profile) || {}"
            :phone-model="(order && order._embedded.phone) || {}"
            @profile="updateProfile"
            @phone="updatePhone"
        />

        <q-separator v-if="hasBaseAccess && hasProfile" class="q-my-md"/>

        <q-legend class="q-mb-sm" :label="$t('Order Contents')"/>

        <order-content
            ref="orderProducts"
            :is-order-loading="!order"
            :order="order"
            :order-type="getOrderType"
            :disabled="isDisabledFields"
            :disabledDelete="isDisabledDelete"
            :shop="order && order._embedded.shop"
            :warehouse="order?.eav && order.eav['order-reserve-warehouse']"
            :reserves="(order && order.reserves) || []"
            :reserves-warehouses="reservesWarehouses"
            @warehouse-change="handleWarehouseChange"
            @shop-change="handleShopChange"
            @source-change="handleSourceChange"
            @update-order="updateOrder"
            @products-change="handleOrderProducts"
            @update-source="updateOrderSource"
        />

        <q-separator class="q-my-md"/>

        <div class="row items-center q-mb-md">
          <q-legend
              :label="
              $t(
                order && order.type === 'return'
                  ? 'Sender address'
                  : 'Recipient address'
              )
            "
          />

          <q-btn-group class="q-ml-sm">
            <q-btn
                v-if="!disabledDomestic"
                size="sm"
                :text-color="isInternational ? 'dark' : 'white'"
                :color="isInternational ? 'grey-3' : 'light-blue-9'"
                :icon="isInternational ? undefined : 'done'"
                :disable="false"
                :label="$t('Domestic')"
                @click="isInternational = false"
            />

            <q-btn
                size="sm"
                :text-color="isInternational ? 'white' : 'dark'"
                :color="isInternational ? 'light-blue-9' : 'grey-3'"
                :icon="isInternational ? 'done' : undefined"
                :disable="false"
                :label="$t('International')"
                @click="isInternational = true"
            />
          </q-btn-group>

          <q-space/>

          <q-btn
              :label="$t('Show pick up points')"
              color="dark"
              class="q-ml-sm"
              size="sm"
              text-color="white"
              :disable="isDisabledFields"
              @click="handleOpenMap"
          />
        </div>

        <c-address
            ref="address"
            :is-loading="!address"
            :type="addressType"
            :disabled="deliveryRequest?.state === 'sent'"
            :model="address || {}"
            @address="updateRecipientAddress"
        >
          <base-address
              :is-loading="!address"
              :address="address || undefined"
              :disabled="deliveryRequest?.state === 'sent'"
              @change="updateRecipientAddress"
          />
        </c-address>

        <q-separator class="q-my-md"/>

        <q-legend class="q-mb-sm" :label="$t('Shipping Request')"/>

        <delivery-request-form
            :is-loading="!deliveryRequest"
            :owner="shopOwner"
            :locality="address && address._embedded.locality"
            :is-locked="isOrderLocked"
            :shipment-date="order && order.shipmentDate"
            @locality="handleLocality"
            @date="handleDate"
            @change="handleDRChange"
            has-ship-date
        />
      </div>

      <sticky-bottom-header
          v-if="order?.state !== 'assembling'"
          :is-loading="isSaveLoading"
          :is-active="hasOrderChange"
          @back="handleDiscard"
          @save="save"
      />
    </div>

    <q-dialog v-model="interactionsModal">
      <q-card style="min-width: 65vw">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('Interactions') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>

        <q-card-section v-if="interactionsModal && order && order.id">
          <n-interactions
              :order="order"
              :delivery-request="deliveryRequest"
              :shop="order._embedded.shop"
          />
        </q-card-section>

        <q-card-section v-else class="alert alert-warning">
          {{ $t('Order not exist!') }}
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="calculatorModal">
      <q-card style="min-width: 60vw">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('Calculator') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>

        <q-card-section v-if="calculatorError" class="alert alert-warning">
          {{ $t(calculatorError) }}
        </q-card-section>

        <q-card-section v-else>
          <d-s-calculator
              ref="calculator"
              :delivery-request="deliveryRequest"
              :weight="sizes.weight"
              :width="sizes.x"
              :height="sizes.y"
              :length="sizes.z"
              :disabled="isDisabledFields"
              @rate="updateRate"
              @on-service-point-change="handleCalculatorSPChange"
              @locality-change="handleLocalityChange"
              @sender-change="handleUpdateSender"
              @update-dimensions="updateDimensions"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showMap" :maximized="$q.screen.width < 700">
      <q-card style="min-width: 90vw">
        <q-card-section class="fit">
          <service-points-map
              :note="address.notFormal"
              :sender="
              deliveryRequest && (deliveryRequest._embedded || {}).sender
            "
              :locality="address._embedded.locality"
              :rate="deliveryRequest && (deliveryRequest._embedded || {}).rate"
              :service-point="
              deliveryRequest && (deliveryRequest._embedded || {}).servicePoint
            "
              @submit="handleMapSubmit"
              @close="handleCloseMap"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="historyModal">
      <q-card style="min-width: 65vw">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('History') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>

        <q-card-section v-if="order && order.id">
          <history
              :id="order.id"
              :entity="'Orderadmin\\Products\\Entity\\AbstractOrder'"
          />
        </q-card-section>

        <q-card-section v-else class="alert alert-warning">
          {{ $t('Order not exist!') }}
        </q-card-section>
      </q-card>
    </q-dialog>

    <where-modal ref="whereModal"/>

    <order-services-modal ref="orderServices"/>

    <chat-modal ref="chatModal"/>

    <document-template-modal
        ref="documentModal"
        :entity-class="'Orderadmin\\Products\\Entity\\AbstractOrder'"
    />

    <eav-modal ref="eavModal" @submit="handleEAVSubmit">
      <form-builder :schema="schema"/>
    </eav-modal>
    <acceptance-items-modal ref="acceptanceModal"/>
    <confirm-modal ref="confirmModal"/>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { transliterate as tr } from 'transliteration'

// Components
import ActionHeader from '../../components/action-header/ActionHeader'
import TableMixin from '../../components/global/TableMixin'
import History from '../../components/history/history'
import NInteractions from '../../components/notifications/Interactions'
import BaseAddress from './../../components/clients/BaseAddress'
import DeliveryRequestForm from './../../components/clients/DeliveryRequestForm'
import ChatModal from '../../components/chat/ChatModal'
import OrderServicesModal from '../../components/modals/OrderServicesModal.vue'
import DocumentTemplateModal from '../../components/modals/DocumentTemplateModal.vue'
import EavModal from '../../components/products/OrderEavModal.vue'
import WhereModal from '../../components/modals/WhereModal.vue'
import ConfirmModal from '../../components/confirm-modal/ConfirmModal'

// Helpers
import orderStatusMatrix from './../../config/OrderMatrix'
import OrderContent from '../../components/products/OrderContent.vue'
import { getRouteOptions } from '@/apps/app/helpers/helpers'
import AcceptanceItemsModal from "@/apps/app/components/modals/AcceptanceItemsModal.vue";

export default {
  name: 'Order',
  components: {
    AcceptanceItemsModal,
    ActionHeader,
    CProfile: defineAsyncComponent(() => import('./../../components/clients/Profile.vue')),
    CAddress: defineAsyncComponent(() => import('../../components/clients/Address.vue')),
    DSCalculator: defineAsyncComponent(() => import('../../components/delivery-services/Calculator.vue')),
    History,
    NInteractions,
    ServicePointsMap: defineAsyncComponent(() => import('./../../components/service-points-map/ServicePointsMap.vue')),
    BaseAddress,
    DeliveryRequestForm,
    ChatModal,
    OrderServicesModal,
    DocumentTemplateModal,
    OrderContent,
    EavModal,
    WhereModal,
    ConfirmModal
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isSaveLoading: false,
      interactionsModal: false,
      calculatorModal: false,
      historyModal: false,
      statuses: orderStatusMatrix,
      showMap: false,
      reservesWarehouses: [],
      isManuallyLocked: false,
      hasChange: false,
      isInternational: this.$utils.getIsInternationalAddress(),
      types: ['retail', 'wholesale', 'return', 'wholesaleDraft'],
      orderProducts: [],
      fskuBarcodes : [],
      disabledDomestic: false,
      storagePlace:  null,
      queue: null,
      serverParamsToGetStoragePlaces: {
        '?per_page': '25',
        'page': '1',
        'order-by': [
          { 'type': 'field', 'field': 'created', 'direction': 'desc' }
        ],
        'filter': [
          { 'type': 'in', 'field': 'state', 'values': ['active', 'normal', 'blocked'] },
          { 'type': 'instanceof', 'field': 'p', 'class': 'Orderadmin\\Storage\\Entity\\Place\\Assembly' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'order',
      'appOptions',
      'cleanOrder',
      'shop',
      'deliveryServices',
      'deliveryRequest',
      'cleanDeliveryRequest',
      'senders',
      'address',
      'cleanAddress',
      'isOrderLocked',
      'lockedOrders',
      'hasOrderChange',
      'hasBaseAccess',
      'isClient'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                value: this.order.extId,
                label: this.$t('Ext ID'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (extId) => {
                  this.updateOrder({ extId })
                }
              }
            ]
          }
        ]
      }
    },
    page () {
      return {
        id: this.order && this.order.id && `P/O/${this.order.id}*`,
        name: this.order && this.order.id
            ? this.$t('Order')
            : this.$t('New order')
      }
    },
    addressType () {
      return this.isInternational
          ? 'international'
          : 'domestic'
    },
    sizes () {
      if (
          this.deliveryRequest &&
          this.deliveryRequest.dimensions &&
          (
              this.deliveryRequest.dimensions.x > 0 ||
              this.deliveryRequest.dimensions.y > 0 ||
              this.deliveryRequest.dimensions.z > 0 ||
              this.deliveryRequest.weight > 0
          )
      ) {
        return {
          x: this.deliveryRequest.dimensions.x || 1,
          z: this.deliveryRequest.dimensions.z || 1,
          y: this.deliveryRequest.dimensions.y || 1,
          weight: this.deliveryRequest.weight || 1
        }
      }

      return this.orderProducts.reduce((acc, item) => {
        if (!item._embedded || !item._embedded.productOffer || !item._embedded.productOffer.dimensions) {
          if (item._embedded && item._embedded.productOffer && item._embedded.productOffer.weight) {
            acc.weight += Number(item._embedded.productOffer.weight) * item.count
          }

          return acc
        }

        const dimensions = item._embedded.productOffer.dimensions

        if ((dimensions.x * item.count) > acc.x) {
          acc.x = Number(dimensions.x) * item.count
        }

        acc.y += Number(dimensions.y) * item.count
        acc.z += Number(dimensions.z) * item.count
        acc.weight += Number(item._embedded.productOffer.weight) * item.count

        return acc
      }, { x: 0, y: 0, z: 0, weight: 0 })
    },
    shopOwner () {
      if (!this.order || !this.order._embedded || !this.order._embedded.shop || !this.order._embedded.shop._embedded || !this.order._embedded.shop._embedded.owner) {
        return null
      }

      return this.order._embedded.shop._embedded.owner
    },
    calculatorError () {
      if (!this.deliveryRequest || !this.deliveryRequest._embedded) {
        return 'Shipping Request data is required!'
      }

      if (!this.order || !this.order._embedded) {
        return 'Order data is required!'
      }

      return ''
    },
    hasDeliveryServiceStatus () {
      return this.deliveryRequest && this.deliveryRequest.deliveryServiceStatus
    },
    hasOrderErrors () {
      return this.order && this.order.orderErrors
    },
    headerModel () {
      if (!this.order) {
        return []
      }

      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: 'default',
        editableFields: false,
        onClick: (state) => {
          if (this.order.id) {
            const hasChange = this.hasOrderChange
            this.$service.order.save({ state }, this.order.id, this.order.type)
                .then(() => {
                  this.updateOrder({ state })
                  this.updateCleanOrder({ state })

                  if (!hasChange) {
                    this.setHasOrderChange(false)
                  }
                })
          }
        },
        value: this.statuses[0].buttons[0],
        options: this.statuses
      }

      const dropdown = {
        section: 'Dropdown',
        className: 'text-center q-pb-sm fit--mobile',
        options: [
          option
        ]
      }

      if (this.order && this.order.state !== option.value.id) {
        this.statuses.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === this.order.state
          })

          if (status) {
            option.color = group.color
            option.value = status
          }

          return status
        })
      }

      const actions = {
        section: 'Actions',
        className: 'col-12 col-sm text-center row items-center justify-center q-gutter-y-xs q-py-xs',
        options: [
          {
            id: 'delivery',
            type: 'dropdown',
            wrapperClassName: 'q-px-xs',
            wrapperStyle: 'max-width: 120px;',
            variant: 'primary',
            label: this.$t('Delivery'),
            options: [
              {
                id: 'calculator',
                type: 'button',
                icon: 'calculate',
                variant: 'light',
                label: this.$t('Calculator'),
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                onClick: () => {
                  this.calculatorModal = true
                }
              },
              {
                id: 'deliveryRequest',
                icon: 'local_shipping',
                label: this.$t('Shipping Request'),
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                onClick: this.handleOpenDeliveryRequest
              },
              {
                id: 'print',
                icon: 'print',
                label: this.$t('Print Label'),
                onClick: this.handlePrint
              },
              {
                if: 'printAll',
                icon: 'print',
                label: this.$t('Print All fnSKU'),
                disabled: !this.fskuBarcodes || this.fskuBarcodes.length === 0,
                onClick: this.printBarcodes
              }
            ]
          },
          {
            id: 'more',
            type: 'dropdown',
            wrapperClassName: 'q-px-xs',
            wrapperStyle: 'max-width: 120px;',
            variant: 'primary',
            label: this.$t('More'),
            options: [
              {
                id: 'history',
                icon: 'history',
                disabled: !this.order || !this.order.id,
                label: this.$t('History'),
                onClick: () => {
                  this.historyModal = true
                }
              },
              {
                id: 'interactions',
                icon: 'phone',
                disabled: !this.order || !this.order.id,
                label: this.$t('Interactions'),
                onClick: () => {
                  this.interactionsModal = true
                }
              },
              {
                id: 'acceptance',
                icon: 'note_add',
                disabled: !this.order || !this.order.id || !this.orderProducts,
                label: this.$t('Create Purchase orders'),
                onClick: () => {
                  let result = this.orderProducts.filter(e => e.productOfferRaw?.type !== 'bundle')
                  result = result.map(x => {
                    return {
                      ...x._embedded.productOffer,
                      ...x,
                      id: x._embedded.productOffer.id,
                      raw: x
                    }
                  })
                  const updatedItems = result.map(item => {
                    return {
                      ...item,
                      sku: Array.isArray(item.barcodes) && item.barcodes.length >= 1
                        ? item.barcodes[0]
                        : '',
                      state: 'new',
                      dimensions: item.dimensions && typeof item.dimensions === 'object'
                        ? item.dimensions
                        : {x: 0, y: 0, z: 0}
                    }
                  })

                  this.$refs.acceptanceModal.show(updatedItems)
                }
              },
              {
                id: 'pack',
                icon: 'inventory_2',
                disabled: !this.order || !this.order.id,
                label: this.$t('Pack order'),
                onClick: () => {
                  return this.loadStoragePlaces(this.serverParamsToGetStoragePlaces)
                    .then(({items, totalPages, page, totalItems}) => {
                      if(items && items.length > 0) {
                        this.storagePlace = items[0]
                      }
                    }).then(() => {
                      const query = {
                        filter: [
                          {
                            type: 'eq',
                            field: 'workingPlace',
                            value: this.storagePlace.id
                          },
                          {
                            type: 'in',
                            field: 'adapter',
                            values: ['parallelAssembling', 'wholesale', 'discrete_assembling', 'ruled']
                          }
                        ]
                      }

                      return this.$service.assemblingQueue.getAll(query)
                        .then(({ items }) => {
                          if(items && items.length > 0) {
                            let data = items[0]
                            this.queue = data

                          }

                          this.$router.push(`/workstation/packing/${this.storagePlace?.id}/${this.queue.id}/${this.order.id}`)
                        })
                    })
                }
              },
              {
                id: 'template',
                icon: 'receipt_long',
                disabled: !this.order || !this.order.id,
                label: this.$t('Documents'),
                onClick: () => {
                  this.$refs.documentModal.open(this.order)
                }
              },
              {
                id: 'copy',
                icon: 'file_copy',
                disabled: !this.order || !this.order.id || !this.order.raw.orderProducts,
                label: this.$t('Duplicate Order'),
                onClick: () => {
                  return this.$refs.confirmModal.show({
                    description: this.$t('Duplicate the order?'),
                    agree: this.$t('Yes'),
                    disagree: this.$t('Discard')
                  })
                      .then(isOk => {
                        if (isOk) {
                          this.$service.order.save(this.order.raw, undefined, this.order.type)
                        }
                      })
                },
                tooltip: !this.order.raw?.orderProducts ? this.$t('An order that was imported from a third-party source cannot be duplicated!') : ''
              },
              {
                id: 'eav',
                icon: 'receipt_long',
                label: this.$t('EAV'),
                onClick: () => {
                  const options = {
                    entityClass: this.$entities.Orderadmin_Products_Entity_Order
                  }

                  this.$refs.eavModal.open(this.order.eav, options)
                }
              },
              {
                id: 'where',
                icon: 'not_listed_location',
                label: this.$t('Where?'),
                onClick: () => {
                  this.$refs.whereModal.open(this.order)
                }
              },
              {
                id: 'lock',
                icon: this.isOrderLocked ? 'lock-open' : 'lock',
                disabled: !this.order || !this.order.id || !this.lockedOrders.includes(this.order.id),
                label: this.$t(this.isOrderLocked ? 'Unlock' : 'Lock'),
                onClick: this.handleUnlock
              }
            ]
          },
          {
            id: 'services',
            wrapperClassName: 'q-px-xs',
            wrapperStyle: 'max-width: 120px;',
            type: 'button',
            variant: 'light',
            disabled: !this.order || !this.order.id,
            label: this.$t('Services'),
            onClick: () => {
              const defaultData = {
                state: 'active',
                order: this.order.id,
                warehouse: this.order._embedded.warehouse,
                currency: this.deliveryRequest && this.deliveryRequest._embedded && this.deliveryRequest._embedded.currency
                    ? this.deliveryRequest._embedded.currency.id
                    : null
              }

              this.$refs.orderServices.open(this.order.id, defaultData)
            }
          },
          {
            id: 'chat',
            wrapperClassName: 'q-px-xs',
            wrapperStyle: 'max-width: 120px;',
            type: 'button',
            icon: 'comments',
            hasIcon: true,
            variant: 'light',
            disabled: !this.order || !this.order.id,
            onClick: () => {
              const entities = [
                { entityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder', entity: this.order.id },
                { entityClass: 'Orderadmin\\Products\\Entity\\Shop', entity: this.order._embedded.shop.id }
              ]

              this.$refs.chatModal.open(entities)
            }
          }
        ]
      }

      let orderTitle = this.$t('New order')

      if (this.order.id) {
        orderTitle = `${this.$t('ID')}: ${this.order.id}`

        if (this.order.extId) {
          orderTitle += `/${this.order.extId}`
        }
      }

      const groups = [
        {
          section: 'BackAction',
          className: 'hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'col-6 col-sm-12 text-right--mobile q-px-xs',
              valueStyle: 'white-space: nowrap',
              additionalValueClassName: 'col-6 col-sm-12 q-px-xs',
              type: 'text',
              value: orderTitle,
              additionalValue: this.order && `${this.$t('Type')}: ${this.$t(this.order.type)}`
            }
          ]
        },
        actions,
        dropdown
      ]

      return groups
    },
    // Get is fields are locked for current order state
    isDisabledDelete () {
      if (!this.order || !this.order.id) {
        return false
      }
      if (this.order.state === 'partly_reserved' || this.order.state === 'cancel' || this.order.state === 'pending_error') {
        return false
      }

      if (this.order.type === 'return' && this.order.state === 'return') {
        return false
      }

      let currentStateOptions = this.statuses[0].buttons[0]

      for (let i = 0; i < this.statuses.length; i++) {
        const group = this.statuses[i]
        const state = group.buttons.find(btn => btn.id === (this.order || {}).state)

        if (state) {
          currentStateOptions = state
          break
        }
      }

      return !currentStateOptions.editableField || this.isOrderLocked
    },
    isDisabledFields () {
      if (!this.order || !this.order.id) {
        return false
      }

      if (this.order.type === 'return' && this.order.state === 'return') {
        return false
      }

      let currentStateOptions = this.statuses[0].buttons[0]

      for (let i = 0; i < this.statuses.length; i++) {
        const group = this.statuses[i]
        const state = group.buttons.find(btn => btn.id === (this.order || {}).state)

        if (state) {
          currentStateOptions = state
          break
        }
      }

      return !currentStateOptions.editableField || this.isOrderLocked
    },
    hasProfile () {
      if (this.getOrderType && this.appOptions.orderTypes[this.getOrderType]?.settings?.disableProfile) {
        return false
      }

      return true
    },
    getOrderType () {
      const url = window.location.href
      const lastSlashIndex = url.lastIndexOf('/')

      if (lastSlashIndex !== -1 && lastSlashIndex + 1 < url.length) {
        return url.substring(lastSlashIndex + 1)
      }

      return null
    }
  },
  watch: {
    orderProducts () {
      this.fskuBarcodes = this.orderProducts.map(e=> {
          if(e.eav && e.eav['integrations-amazon-offer-fnsku'] ) {
              return  { barcode : e.eav['integrations-amazon-offer-fnsku'] , count: e.count}
          }
          return null
      }).filter(item => item !== null);

      if (!this.deliveryRequest) {
        return
      }

      this.updatePrices()
    },
    order (newValue, oldValue) {
      if ((newValue || {}).paymentState !== (oldValue || {}).paymentState) {
        this.updatePrices()
      }
    },
    cleanOrder () {
      this.hasChange = false
    }
  },
  mounted () {
    this.setHasOrderChange(false)
    this.loadFullOrder()
    this.isDisabledDomestic()
  },
  unmounted () {
    this.setHasOrderChange(false)

    if (this.order && this.order.id) {
      if (!this.lockedOrders.includes(this.order.id)) {
        this.$channels.user.publish('closeOrder', this.order.id)
      }

      if (this.isManuallyLocked) {
        this.removeLockedOrder(this.order.id)
      }
    }

    this.resetOrder()
  },
  methods: {
    ...mapActions([
      'resetOrder',
      'loadStoragePlaces',
    ]),
    ...mapMutations([
      'updateOrderSource',
      'updateOrder',
      'setNewAddress',
      'setNewOrder',
      'setNewDeliveryRequest',
      'updateDeliveryRequest',
      'addWarningNotification',
      'addErrorNotification',
      'setDeliveryRequest',
      'setOrder',
      'addLockedOrder',
      'removeLockedOrder',
      'updateCleanOrder',
      'setHasOrderChange',
      'updateDeliveryRequestEmbedded',
      'setAddress',
      'updateAddress',
      'upsertOrder',
      'updateOrderEmbedded',
      'upsertDeliveryRequest'
    ]),
    printBarcodes () {
      this.$service.printer.generatePDFBarcodes(this.fskuBarcodes.map(barcode => {
        return {
          size: '30x30',
          barcode: barcode.barcode,
          forceNoAutoClose: true,
          count: barcode.count
        }
      }))
    },
    updateDimensions (dimensions) {
      this.updateDeliveryRequest(dimensions)
      this.calculatorModal = false
      this.setHasOrderChange(true)
    },
    isDisabledDomestic () {
      const options = getRouteOptions('address')

      if (options.additionalProperties && options.additionalProperties.disabledFor) {
        const disabledFor = options.additionalProperties.disabledFor

        if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.id)) {
          this.disabledDomestic = true
          return
        }
      }

      return
    },
    handleOrderProducts (products) {
      this.orderProducts = products
    },
    handleEAVSubmit (eav) {
      this.updateOrder({ eav })
      this.hasChange = true
    },
    loadFullOrder () {
      // If no if in route then create new order
      if (!this.$route.params.id) {
        if (!this.$route.query.hasInit || !this.order) {
          this.setNewOrder({ type: this.$route.params.type })
          this.setNewAddress()
          this.setNewDeliveryRequest()
          this.setHasOrderChange(false)
        }

        if (this.$route.query.hasInit) {
          this.$refs.orderProducts.setProducts(this.$store.getters.orderProducts)
        }

        if (!this.order._embedded.shop) {
          const query = {
            per_page: 5,
            page: 1,
            filter: [
              { type: 'in', field: 'state', values: ['active', 'blocked'] },
              { type: 'in', field: 'type', values: ['shop', 'virtual'] }
            ]
          }

          this.$service.shop.getAll(query)
              .then(({ items, totalItems }) => {
                if (totalItems === 1) {
                  this.updateOrderEmbedded({ shop: items[0] })
                }
              })
        }

        return
      }

      return this.$service.deliveryOrder.get(this.$route.params.id)
          .then(({ order, deliveryRequest, address }) => {
            if (Array.isArray(order.eav)) {
              order.eav = {}
            }
            this.setOrder(order)
            this.setDeliveryRequest(deliveryRequest)
            this.setAddress(address)
            this.submitOpenOrder()
            this.setWarehousesByReserves(this.order.reserves || [])
            this.loadWarehouseByOrderEav(this.order)
            this.setHasOrderChange(false)

          })
    },
    handleDRChange () {
      this.setHasOrderChange(true)
    },
    handleDate (shipmentDate) {
      this.updateOrder({ shipmentDate })
    },
    handleShopChange (shop) {
      if (this.order.id && this.order._embedded.shop && this.order._embedded.shop.id) {
        return
      }

      this.updateOrderEmbedded({ shop })
    },
    handleWarehouseChange (warehouse) {
      if (!this.order.eav) {
        this.order.eav = {}
      }

      this.order.eav['order-reserve-warehouse'] = warehouse.id
    },
    handleSourceChange (source) {
      if (this.order.id) {
        return
      }

      this.updateOrderEmbedded({ source })
    },
    // Indicate Centrifugo which order is open
    submitOpenOrder () {
      if (this.lockedOrders.includes(this.order.id)) {
        return
      }

      this.$channels.user.publish('openOrder', this.order.id)
    },
    setWarehousesByReserves (reserves) {
      return this.loadWarehousesByReserves(reserves)
          .then(({ items }) => {
            this.reservesWarehouses = items
            this.setHasOrderChange(false)
          })
    },
    loadWarehousesByReserves (reserves) {
      if (reserves.filter(val => val).length === 0) {
        return Promise.resolve({ items: [] })
      }

      // Extract warehouses from reserves
      const values = reserves.reduce((acc, reserve) => {
        if (reserve && !acc.includes(reserve.warehouse)) {
          acc.push(reserve.warehouse)
        }

        return acc
      }, [])

      const query = {
        per_page: values.length,
        page: 1,
        filter: [
          { type: 'in', field: 'id', values },
          { type: 'in', field: 'type', values: ['client', 'virtual'] }
        ]
      }

      return this.$service.warehouse.getAll(query)
    },
    loadWarehouseByOrderEav (order) {
      if (!order.eav || !order.eav['order-reserve-warehouse']) {
        return Promise.resolve()
      }

      return this.$service.warehouse.get(order.eav['order-reserve-warehouse'])
          .then(warehouse => {
            if (!this.order) {
              return
            }

            this.updateOrderEmbedded({ warehouse })
            this.updateCleanOrder({
              _embedded: { ...this.cleanOrder._embedded, warehouse }
            })
            this.setHasOrderChange(false)
          })
    },
    handleLocality (locality) {
      if (!locality || !locality.id) {
        return
      }

      this.handleLocalityChange(locality)
    },
    handleUnlock () {
      if (this.isOrderLocked) {
        this.isManuallyLocked = false
        return this.removeLockedOrder(this.order.id)
      }

      this.isManuallyLocked = true
      return this.addLockedOrder(this.order.id)
    },
    // Change delivery request prices depend on order products
    updatePrices () {
      if (!this.deliveryRequest) {
        return
      }

      const orderPrice = this.orderProducts.reduce((acc, val) => {
        return acc + (Number(val.price) || 0) * (Number(val.count) || 1)
      }, 0)

      const update = {
        payment: this.order.paymentState === 'not_paid'
            ? orderPrice
            : 0,
      }

      this.updateDeliveryRequest(update)
    },
    handleCloseMap () {
      this.showMap = false
    },
    handleOpenMap () {
      this.showMap = true
    },
    // Handle address change from map
    handleMapSubmit (data) {
      const address = { ...this.address, _embedded: { ...this.address._embedded } }
      address._embedded.locality = data.locality

      this.updateDeliveryRequestEmbedded({
        sender: data.sender,
        rate: data.rate,
        servicePoint: data.servicePoint
      })

      this.updateRecipientAddress(address)
      this.showMap = false
    },
    updateRate (rate, isDimensions) {
      this.updateDeliveryRequestEmbedded({ rate, deliveryService: rate.deliveryService, servicePoint: null })

      if (rate.raw) {
        this.updateDeliveryRequest({
          eav: {
            ...this.deliveryRequest?.eav,
            'delivery-services-calculation-result': rate.raw
          }
        })
      }

      if (!isDimensions) {
        this.calculatorModal = isDimensions
      }
      this.setHasOrderChange(true)
    },
    handleLocalityChange (locality) {
      this.updateRecipientAddress({
        ...this.address,
        _embedded: {
          ...this.address._embedded,
          locality
        }
      })
    },
    handleUpdateSender (sender) {
      this.updateDeliveryRequestEmbedded({ sender })
    },
    handleCalculatorSPChange (servicePoint) {
      this.updateDeliveryRequestEmbedded({ servicePoint })
      this.calculatorModal = false
    },
    save () {
      if (!this.order.id) {
        // const profileError = this.$utils.validate.profile(this.order._embedded.profile)
        //
        // if (profileError) {
        //   this.addErrorNotification(profileError)
        //   return
        // }

        const orderError = this.$utils.validate.order(this.order, this.getOrderType)

        if (orderError) {
          this.addErrorNotification(orderError)
          return
        }

        const isCourier = this.deliveryRequest._embedded.rate
            ? !['service_point', 'self_service_point', 'simple'].includes(this.deliveryRequest._embedded.rate.type)
            : true

        if (this.$service.deliveryOrder.requiredAddressFields.find(x => this.address[x] || this.address._embedded[x])) {
          const addressError = this.$utils.validate.address(this.address, isCourier, !this.isInternational)

          if (addressError) {
            this.addErrorNotification(addressError)
            return
          }

          if (!this.$service.deliveryOrder.requiredDeliveryRequestFields.find(x => this.deliveryRequest[x] || this.deliveryRequest._embedded[x])) {
            this.addErrorNotification('Please fill required data for shipping request')
            return
          }
        }

        if (this.$service.deliveryOrder.requiredDeliveryRequestFields.find(x => this.deliveryRequest[x] || this.deliveryRequest._embedded[x])) {
          const deliveryRequestError = this.$utils.validate.baseDeliveryRequest(this.deliveryRequest, this.order.type === 'return')

          if (deliveryRequestError) {
            this.addErrorNotification(deliveryRequestError)
            return
          }
        }
      }
      if (this.order.type === 'return' && (!this.address.street || !this.address?._embedded?.locality.id) ) {
        this.addErrorNotification('You can\'t save order without address!')
        return
      }

      if (this.order.type !== 'return' && this.$refs.orderProducts.getAll().length === 0) {
        this.addErrorNotification('You can\'t save order without products!')
        return
      }

      this.isSaveLoading = true
      return this.$service.deliveryOrder.save(
          { update: this.order, clean: this.cleanOrder },
          { update: this.deliveryRequest, clean: this.cleanDeliveryRequest },
          { update: this.address, clean: this.cleanAddress },
          { update: this.$refs.orderProducts.getChanges() }
      )
          .then(({ order, deliveryRequest, address }) => {
            this.upsertOrder({ ...order, _embedded: { ...order._embedded, deliveryRequest } })
            this.setHasOrderChange(false)

            if (deliveryRequest && deliveryRequest.id) {
              this.upsertDeliveryRequest(deliveryRequest)
            }

            if (!this.order.id) {
              this.handleBack()
              return
            }

            this.setOrder(order)
            this.setDeliveryRequest(deliveryRequest)
            this.setAddress(address)

            this.$refs.orderProducts.reset()
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    handleOpenDeliveryRequest () {
      if (!this.deliveryRequest.id) {
        this.addWarningNotification('Shipping request not exist!')
        return
      }

      this.setDeliveryRequest(this.deliveryRequest)
      this.$router.push('/outbound/shipments/entity/' + this.deliveryRequest.id)
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    handlePrint () {
      const shop = tr(this.order?._embedded?.shop?.name)
          + "\n" + this.order?.shipmentDate
          + "\n" + this.order?._embedded?.deliveryRequest?._embedded?.deliveryService?.name

      this.$service.printer.generateBarcode({
        barcode: `P/O/${this.order.id}*`,
        label: `${shop}`,
        size: '88x44',
        fontSize: '13'
      })
    },
    updateProfile (profile) {
      this.updateOrderEmbedded({ profile })
    },
    updatePhone (phone) {
      this.updateOrderEmbedded({ phone })
    },
    updateRecipientAddress (address) {
      this.updateAddress(address)
      this.setHasOrderChange(true)
    }
  }
}
</script>>
