<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw; overflow: visible;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Order Settings') }}
        </div>
        <q-space/>
        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <div
          v-if="isLoading"
          class="text-primary row fit justify-center items-center"
          style="min-height: 300px;"
      >
        <q-spinner color="light-blue-9" size="3rem"/>
      </div>

      <q-card-section v-else class="text-center">
        <q-scroll-area style="height: 400px;" visible>
          <!-- If no schema groups, show a message -->
          <div v-if="!schema.groups.length" class="text-subtitle1">
            {{ $t('No Reserve Settings Available') }}
          </div>
          <!-- Otherwise, use the form-builder to render the schema -->
          <form-builder v-else :schema="schema" v-model="model"/>
        </q-scroll-area>
      </q-card-section>

      <q-card-section v-if="!isLoading" class="text-center">
        <q-btn
            color="dark"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Close')"
            @click="close"
        />
        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Ok')"
            @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'OrderEavModal',
  emits: ['submit'],
  props: {
    eav: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isOpen: false,
      model: null,
      options: {},
      eavs: [],
      isLoading: false
    }
  },
  computed: {
    schema() {
      return {
        groups: this.eavs.reduce((acc, eav) => {
          return [...acc, ...this.createSchema(eav.element), ...this.getStaticSchema().groups]
        }, [])
      }
    },
    // Check if any loaded EAV item has a code of 'storage-reserve-settings'
    // (Adjust this condition based on your data structure.)
    hasReserveSettingsField() {
      return this.eavs.some(eav => eav.code === 'storage-reserve-settings')
    }
  },
  methods: {
    close() {
      this.isOpen = false
    },
    getModel(model) {
      if (!this.model && Array.isArray(model)) {
        return {}
      }
      return _.cloneDeep(model)
    },
    open(model = {}, options) {
      this.options = options
      this.model = this.getModel(model)
      this.isOpen = true
      this.loadFields()
    },
    // Dynamic schema creation (existing behavior)
    createSchema(groups) {
      return [
        {
          styleClasses: 'row',
          fields: groups.reduce((acc, group) => {
            return [
              ...acc,
              ...Object.values(group).map(field => {
                if (field.name === 'storage-reserve-settings') {
                  return false;
                }

                const newField = this.$utils.field.create(
                    this.model,
                    field.spec ? field.spec : field
                )
                if (newField) {
                  newField.wrapperStyleClasses = 'col-12 q-pa-xs'
                } else {
                  console.debug(field)
                }
                return newField
              })
            ]
          }, [])
        }
      ]
    },
    // Static schema for reserve settings
    getStaticSchema() {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                name: 'place-types',
                value: this.model?.['storage-reserve-settings']?.['place-types'],
                label: this.$t('Place Types'),
                type: 'tag',
                multiple: true,
                options: [
                  {label: 'Static', value: 'static'},
                  {label: 'Dynamic', value: 'dynamic'},
                  {label: 'Sorting', value: 'sorting'},
                  {label: 'Defected', value: 'defected'},
                  {label: 'Pallet', value: 'pallet'},
                ],
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customTag: (item) => {
                  if (item && typeof item === 'object') {
                    return item.label
                  }

                  return item;
                },
                customListItem: (row) => {
                  return row.label;
                },
                onAdd: (state) => {
                  // Ensure that `this.model` and its nested object exist
                  if (!this.model) {
                    this.model = {};
                  }
                  if (!this.model['storage-reserve-settings']) {
                    this.model['storage-reserve-settings'] = {};
                  }
                  if (!this.model['storage-reserve-settings']['place-types']) {
                    this.model['storage-reserve-settings']['place-types'] = [];
                  }

                  this.model['storage-reserve-settings']['place-types'].push(state.value);
                },
                onRemove: (state) => {
                  const index = this.model['storage-reserve-settings']['place-types'].indexOf(state.value);
                  if (index !== -1) {
                    this.model['storage-reserve-settings']['place-types'].splice(index, 1); // Remove the element
                  }
                }
              },
              {
                name: 'expires.start',
                label: this.$t('Expires Start'),
                value: this.model?.['storage-reserve-settings']?.expires?.start,
                type: 'date',
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (value) => {
                  // Ensure that `this.model` and its nested object exist
                  if (!this.model) {
                    this.model = {};
                  }
                  if (!this.model['storage-reserve-settings']) {
                    this.model['storage-reserve-settings'] = {};
                  }
                  if (!this.model['storage-reserve-settings']['expires']) {
                    this.model['storage-reserve-settings']['expires'] = {};
                  }

                  this.model['storage-reserve-settings']['expires']['start'] = value;
                }
              },
              {
                name: 'expires.end',
                label: this.$t('Expires End'),
                value: this.model?.['storage-reserve-settings']?.expires?.end,
                type: 'date',
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (value) => {
                  if (!this.model) this.model = {};
                  if (!this.model['storage-reserve-settings']) this.model['storage-reserve-settings'] = {};
                  if (!this.model['storage-reserve-settings']['expires']) this.model['storage-reserve-settings']['expires'] = {};

                  this.model['storage-reserve-settings']['expires']['end'] = value;
                }
              },
              {
                name: 'productiondate.start',
                label: this.$t('Production Date Start'),
                value: this.model?.['storage-reserve-settings']?.productiondate?.start,
                type: 'date',
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (value) => {
                  if (!this.model) this.model = {};
                  if (!this.model['storage-reserve-settings']) this.model['storage-reserve-settings'] = {};
                  if (!this.model['storage-reserve-settings']['productiondate']) this.model['storage-reserve-settings']['productiondate'] = {};

                  this.model['storage-reserve-settings']['productiondate']['start'] = value;
                }
              },
              {
                name: 'productiondate.end',
                label: this.$t('Production Date End'),
                value: this.model?.['storage-reserve-settings']?.productiondate?.end,
                type: 'date',
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (value) => {
                  if (!this.model) this.model = {};
                  if (!this.model['storage-reserve-settings']) this.model['storage-reserve-settings'] = {};
                  if (!this.model['storage-reserve-settings']['productiondate']) this.model['storage-reserve-settings']['productiondate'] = {};

                  this.model['storage-reserve-settings']['productiondate']['end'] = value;
                }
              },
              {
                name: 'batch',
                label: this.$t('Batch (comma separated)'),
                value: this.model?.['storage-reserve-settings']?.batch?.join(', ') || '',
                type: 'input',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (value) => {
                  if (!this.model) this.model = {};
                  if (!this.model['storage-reserve-settings']) this.model['storage-reserve-settings'] = {};

                  this.model['storage-reserve-settings']['batch'] = value ? value.split(',').map(item => item.trim()) : [];
                }
              },
              {
                name: 'serialnumber',
                label: this.$t('Serial Number (comma separated)'),
                value: this.model?.['storage-reserve-settings']?.serialnumber?.join(', ') || '',
                type: 'input',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (value) => {
                  if (!this.model) this.model = {};
                  if (!this.model['storage-reserve-settings']) this.model['storage-reserve-settings'] = {};

                  this.model['storage-reserve-settings']['serialnumber'] = value ? value.split(',').map(item => item.trim()) : [];
                }
              }
            ]
          }
        ]
      }
    },
    loadFields() {
      this.isLoading = true
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          {type: 'eq', field: 'state', value: 'active'},
          {type: 'eq', field: 'backendEntity', value: this.options.entityClass}
        ]
      }
      return this.$service.eav
          .getAll(query)
          .then(data => {
            this.eavs = data.items
            return data
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    save() {
      this.close()
      this.$emit('submit', this.model)
    }
  }
}
</script>